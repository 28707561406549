.popup { 
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
  }
  
  .popup_inner {
    position: absolute;
    left: 30%;
    right: 30%;
    top: 10%;
    bottom: 30%;
    margin: auto;
    border-radius: 10px;
    background: white;
  }
  
  .popup_header {
    font-size: x-large;
    text-align: center;
  }
  
  .popup_body {
    margin-left: 10%;
    margin-right: 10%;    
  }
  
  .sectionBtnClose {
    margin-left: 10%;
    margin-right: 10%; 
  }
  
  .divscroll {
    width:100%;
    overflow:auto; 
    max-height:180px;
  }
  
  